/* 

KLEUREN:

PAARS: #941c81
ROOD: #E42430;
GROEN: #27ae60
GEEL: #f1c40f 

MAIN: #2f5e66
SECONDARY: #c6d2c6


*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
}

.container {
  padding: 0 12px;
}

.row {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.btn:hover {
  color: #e42430;
}

.btn {
  line-height: 80px;
  font-size: 18px;
  padding: 12px 20px;
  border-radius: 100px;
  width: 100%;
  border: 2px solid #e42430;
  vertical-align: middle;
  color: #231f20;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.35px;
  transition: all 0.3s ease;
}

.btn .icon {
  font-size: 28px;
  line-height: 10px;
  vertical-align: middle;
  padding-left: 20px;
  margin-bottom: 3px;
  color: #e42430;
}

/*

  QUOTE

*/

.quote {
  padding: 80px 0;
  background-color: #f5f5f5;
}

.quote__text {
  padding: 0 200px;
}

.quote__text--para {
  font-size: 32px;
  color: #231f20;
  font-weight: 700;
  text-align: center;
}

.quote__text--para span {
  color: #e42430;
}

/*

  NAVBAR

*/

nav {
  background-color: #fff;
}

.nav__line {
  background: #eaf2f4;
  height: 60%;
  width: 2px;
  padding: 24px 0;
}

.nav__container {
  z-index: 5;
  padding: 8px 12px;
  height: 100px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.nav__links {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btn__menu {
  display: none;
}

.nav__logo--container {
  width: 216px;
}

.nav__logo {
  height: 100%;
  padding: 12px;
}

.nav__list {
  display: flex;
  align-items: center;
  gap: 2px;
}

.nav__list span {
  font-weight: 700;
  font-size: 16px;
  color: #e42430;
  margin-right: 4px;
}

.nav__link {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  color: #231f20;
  font-size: 14px;
  letter-spacing: 1px;
  transition: 0.3s ease;
}

.nav__link--menu {
  color: #e42430;
  font-size: 32px;
}

.nav__link:hover {
  color: #e42430;
  text-decoration: none;
}

/*

  FOOTER

*/

footer {
  background-color: #231f20;
  color: #fff;
}

.footer__item--logo-img {
  width: 95%;
  filter: brightness(0) invert(1);
}

.footer__wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
}

.footer__socials {
  display: flex;
  gap: 20px;
}

.footer__socials a {
  color: #e42430;
  font-size: 24px;
}

.footer__list {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.footer__item--para {
  font-weight: lighter;
}

.footer__list--title-h3 {
  color: #e42430;
  font-size: 16px;
}

.footer__item--link {
  font-size: 16px;
  color: white;
  font-weight: lighter;
}

.footer__link--bold {
  font-weight: bold;
  color: #fff;
}

.footer__noextend {
  justify-content: start;
}

footer a:hover {
  text-decoration: underline;
}

/*

  PRIVACY

*/

.privacy {
  background-color: #fff;
  padding: 28px 0;
  color: #231f20;
}

.privacy__wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy__para {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 300;
}

.privacy__link {
  color: #231f20;
}

.privacy__para--link {
  font-weight: 600;
  color: #e42430;
}

.bullet::before {
  content: "\2022";
  color: #e42430;
  font-size: 24px;
  padding: 0 10px;
}

/*

  BACKDROP MENU

*/

.contact__list--link span,
.contact__list--link a {
  font-size: 24px;
}

.menu__bar {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 50%;
}

.menu__image {
  height: 200px;
}

.menu__image--img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.menu__links {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #231f20;
  font-size: 28px;
  padding: 24px;
  border-bottom: 2px solid #eaf2f4;
  font-weight: 600;
  transition: 0.3s ease;
}

.menu__list:hover {
  color: #e42430;
  border-bottom: 2px solid #e42430;
}

.menu__backdrop {
  display: none;
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 100%;
  padding-bottom: 16px;
  z-index: 1;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  animation: menuBackdrop 0.4s linear;
}

.menu__backdrop .row {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 24px;
}

.menu--open .menu__backdrop {
  display: block;
}

.menu--open .nav__link--menu-close,
.nav__link--menu-open {
  display: none;
}

.menu--open .nav__link--menu-open {
  display: block;
}

/*

  LANDING

*/

.landing {
  margin-bottom: 52px;
}

.landing .container {
  display: flex;
  align-items: center;
  padding: 0;
}

.landing__title {
  padding-left: 12px;
  margin-left: calc(100% - 600px);
  margin-bottom: 24px;
}

.landing__title--span {
  font-weight: 600;
  color: #e42430;
}

.landing__title--head {
  font-size: 40px;
  max-width: 90%;
  color: #231f20;
}

.landing__image {
  position: relative;
  height: 500px;
  width: 100%;
}

.landing__image--img {
  filter: brightness(80%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
}

.landing__services {
  position: absolute;
  bottom: -40px;
  padding: 0 52px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.landing__services--service {
  text-align: center;
  padding: 20px;
  color: white;
  width: 100%;
}

.service--purple {
  border: 5px solid #721861;
  border-right: none;
  background-color: #941c81;
  border-radius: 12px 0 0 12px;
}

.service--red {
  background-color: #e42430;
  border: 5px solid #a70a14;
  border-right: none;
  border-left: none;
}

.service--green {
  background-color: #27ae60;
  border: 5px solid #178847;
  border-right: none;
  border-left: none;
}

.service--yellow {
  background-color: #f1c40f;
  border: 5px solid #d4b32b;
  border-left: none;
  border-radius: 0 12px 12px 0;
}

.landing__title--head span {
  color: #e42430;
  font-style: italic;
}

/*

  ABOUT

*/

.about {
  padding: 40px;
}

.about__title {
  font-size: 40px;
  color: #231f20;
}

.about__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.about__description {
  width: 100%;
}

.about__image {
  width: 50%;
  height: 450px;
}

.about__image--img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.about__para b {
  font-size: 28px;
}

.about__para b span {
  color: red;
}

/*

  SERVICES

*/

.services {
  padding: 40px 0;
  background-color: #f5f5f5;
}

.services__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.services__description.red {
  background-color: #e42430;
  border: 4px solid #a70a14;
}

.services__description.green {
  background-color: #27ae60;
  border: 4px solid #178847;
}

.services__description.yellow {
  background-color: #f1c40f;
  border: 4px solid #d4b32b;
}

.services__description.purple {
  background-color: #941c81;
  border: 4px solid #721861;
}

.services__description {
  bottom: 0;
  position: absolute;
  border-radius: 16px;
  padding: 12px 20px;
  color: #fff;
  width: 45%;
  transition: all 0.3s ease;
}

.services__description--title {
  font-size: 28px;
  margin-bottom: 12px;
}

.services__description--para {
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 400;
}

.services__image {
  width: 100%;
  flex-grow: 1;
  height: 600px;
  display: flex;
  justify-content: end;
  transition: all 0.3s ease;
}

.services__image--img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.services__list {
  width: 300px;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.services__title {
  font-size: 44px;
}

.services__list b {
  color: #e42430;
}

.services__list .icon {
  padding: 0 8px;
  font-size: 20px;
  font-weight: 400;
}

.services__list li button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 20px;
  transition: 0.3s ease all;
}

.services__list li button:hover {
  color: #e42430;
}

/*

  CONTACT

*/

.contact {
  padding: 40px 0;
}

.contact__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.contact__information,
.contact__map {
  width: 50%;
}

.contact__details {
  font-size: 24px;
  font-weight: 600;
  color: #231f20;
  transition: all 0.3s ease;
}

.contact__details:hover {
  color: #e42430;
}

.contact__details--span {
  margin-right: 8px;
  color: #e42430;
}

.contact__description {
  margin: 24px 0;
}

.contact__title--span {
  color: #e42430;
  font-weight: 600;
}

.contact__title {
  font-size: 40px;
}

@keyframes menuBackdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .contact__wrapper {
    flex-direction: column;
  }
  .contact__information,
  .contact__map {
    width: 100%;
  }
  .landing__title {
    margin-left: 0;
  }
  .about__wrapper {
    flex-direction: column;
  }
  .about__image {
    width: 100%;
    height: 300px;
  }
  .privacy__wrapper {
    flex-direction: column;
    gap: 12px;
  }
  .footer__item--para {
    font-size: 12px;
  }
  .contact__information {
    text-align: center;
  }
  .services__wrapper {
    flex-direction: column;
  }
  .services__description {
    margin-top: 12px;
    position: static;
    width: 100%;
  }
  .services__list {
    gap: 8px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .services__list li {
    width: calc(50% - 4px);
    text-align: center;
    border-radius: 8px;
    padding: 16px 22px;
  }
  .services__list li button,
  .services__list li button b {
    color: #fff;
    font-size: 16px;
  }
  .services__list li button:hover {
    color: #fff;
    font-size: 16px;
  }
  .services__list li .icon {
    display: none;
  }
  .services__list li.red {
    background-color: #e42430;
    border: 4px solid #a70a14;
  }
  .services__list li.green {
    background-color: #27ae60;
    border: 4px solid #178847;
  }
  .services__list li.yellow {
    background-color: #f1c40f;
    border: 4px solid #d4b32b;
  }
  .services__list li.purple {
    background-color: #941c81;
    border: 4px solid #721861;
  }
  .services__image {
    height: 300px;
  }
  .landing__image {
    height: 250px;
  }
  .landing__image--img {
    border-radius: 0;
  }
  .landing__title {
    padding: 24px 12px;
    margin-bottom: 0;
  }
  .landing__title--head {
    max-width: 100%;
    font-size: 28px;
  }
  .landing .container {
    flex-direction: column-reverse;
  }
  .about {
    padding: 24px 0;
  }
  .about__description {
    text-align: center;
    font-size: 14px;
  }
  .about__title {
    font-size: 32px;
  }
  .quote__text {
    padding: 0 10px;
  }
  .quote__text--para {
    font-size: 24px;
  }
  .btn__menu {
    display: block !important;
  }
  .nav__phone,
  .nav__line,
  .footer__middle {
    display: none;
  }
  .footer__list {
    width: 80%;
  }
  .menu__links {
    width: 100%;
  }
  .menu__image {
    display: none;
  }
  .nav__logo {
    height: 90%;
  }
  .nav__link {
    font-size: 16px;
  }
  .menu__bar {
    width: 100%;
    padding: 0 12px;
  }
  .contact__list--link,
  .contact__list--link span,
  .contact__list--link a {
    display: flex;
    font-size: 16px;
  }
  .menu__backdrop .row {
    flex-direction: column-reverse;
  }
  .menu__contact--list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1200px) {
  .landing__title {
    margin-left: 0;
  }
}
